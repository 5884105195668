import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import Textarea from "@mui/joy/Textarea";
import { IconButton, Stack } from "@mui/joy";

import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import { ChatProps } from "../types";

export type MessageInputProps = {
  textAreaValue: string;
  setTextAreaValue: (value: string) => void;
  selectedConsultation: ChatProps | null;
  onSubmit: () => void;
  onFileUpload: (file: File) => void;
};

export default function MessageInput(props: MessageInputProps) {
  const {
    textAreaValue,
    setTextAreaValue,
    selectedConsultation,
    onSubmit,
    onFileUpload,
  } = props;
  const textAreaRef = React.useRef<HTMLDivElement>(null);
  const handleClick = () => {
    if (textAreaValue.trim() !== "") {
      onSubmit();
      setTextAreaValue("");
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      onFileUpload(file);
    }
  };

  const examples = [
    "I have been feeling fatigued for the past few weeks. What could be causing it?",
    "I'm struggling with my diet and looking for some suggestions to improve my eating habits.",
    "I recently injured my knee, and it's been swelling up. Can you help me understand why?",
    "I've been experiencing frequent headaches lately. Could this be a sign of something serious?",
    "Can you suggest a workout routine for weight loss?",
    "I'm having trouble sleeping at night. Do you have any tips on how to improve my sleep?",
    "I have digestive issues and want to know what changes I can make to my diet.",
    "I've been feeling anxious lately, and I'm not sure why. What could be the cause?",
    "I've recently started taking a new medication and want to know if it's causing any side effects.",
    "What can I do to boost my immune system during the cold season?",
    "I'm pregnant and want to know what foods I should be eating and avoiding.",
    "I've been experiencing chest pain occasionally. What should I do?",
    "What are some good exercises to relieve back pain?",
    "Can you help me with a dietary plan to manage my blood sugar levels?",
    "I'm looking for suggestions on how to manage stress better.",
    "I've been having joint pain. Is there anything I should be concerned about?",
  ];

  const [currentExample, setCurrentExample] = React.useState(examples[0]);
  const [_, setExampleIndex] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setExampleIndex((prevIndex) => {
        const nextIndex = (prevIndex + 1) % examples.length;
        setCurrentExample(examples[nextIndex]);
        return nextIndex;
      });
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{ px: 2, pb: 3 }}>
      <FormControl>
        <Textarea
          placeholder={selectedConsultation ? "Send message…" : currentExample}
          aria-label="Message"
          ref={textAreaRef}
          onChange={(event) => {
            setTextAreaValue(event.target.value);
          }}
          value={textAreaValue}
          minRows={1}
          maxRows={10}
          endDecorator={
            <Stack
              direction="row"
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                flexGrow: 1,
                py: 1,
                pr: 1,
                borderTop: "1px solid",
                borderColor: "divider",
              }}
            >
              <div>
                <IconButton
                  component="label"
                  size="sm"
                  variant="plain"
                  color="neutral"
                >
                  <AttachFileRoundedIcon />
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={handleFileChange}
                  />
                </IconButton>
              </div>
              <Button
                size="sm"
                color="primary"
                sx={{ alignSelf: "center", borderRadius: "sm" }}
                endDecorator={<SendRoundedIcon />}
                onClick={handleClick}
                disabled={!textAreaValue.trim()}
              >
                Send
              </Button>
            </Stack>
          }
          onKeyDown={(event) => {
            if (event.key === "Enter" && (event.metaKey || event.ctrlKey)) {
              handleClick();
            }
          }}
          sx={{
            "& textarea:first-of-type": {
              minHeight: 48,
            },
          }}
        />
      </FormControl>
    </Box>
  );
}
